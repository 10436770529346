<template>
  <v-container class="response__container">
    <v-list-item-content></v-list-item-content>
    <b-list-group>
      <b-list-group-item>
        <div
          v-if="contributionDetails.status == 'SUCCESS'"
          class="align-end"
          style="color:green;"
        >
          <b-alert show variant="success"
            >Thank you for your contribution.</b-alert
          >
        </div>
        <div v-else class="align-end" style="color:red;">
          <b-alert show variant="danger"
            >It looks like the contribution did not get through. Please find the
            details below.</b-alert
          >
        </div>
      </b-list-group-item>

      <b-list-group-item
        class="d-flex justify-content-center align-items-center align-self-stretch"
      >
        <label>Donor Name:</label>
        <label class="p-2"
          ><b>&nbsp;{{ juspayStatusDetails.donorName }}</b></label
        >
      </b-list-group-item>

      <div v-show="contributionDetails.status == 'SUCCESS'">
        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>{{ panelItems.transId }}:</label>
          <label class="p-2"
            ><b>&nbsp;{{ juspayStatusDetails.transactionId }}</b></label
          >
        </b-list-group-item>
      </div>

      <b-list-group-item>
        <v-row>
          <v-col class="text-center" cols="6" sm="6" lg="6">
            <b-button pill variant="outline-secondary" v-on:click="home"
              >CONTRIBUTE AGAIN</b-button
            >
          </v-col>
          <v-col class="text-center" cols="6" sm="6" lg="6">
            <b-button
              v-show="contributionDetails.status == 'SUCCESS'"
              pill
              variant="success"
              v-on:click="receipt"
              >PROVISIONAL RECEIPT</b-button
            >
          </v-col>
        </v-row>
      </b-list-group-item>
    </b-list-group>

    <b-list-group>
      <v-card>
        <v-card-title class="subheading font-weight-bold justify-content-center"
          >Contribution Details
        </v-card-title>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Donation Id:</label>
          <label class="p-2"
            ><b>&nbsp;{{ this.contributionDetails.donationId }}</b></label
          >
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Project Name:</label>
          <label class="p-2"
            ><b>&nbsp;{{ juspayStatusDetails.projectName }}</b></label
          >
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Donation Amount:</label>
          <label class="p-2"
            ><b
              >&nbsp;{{ this.contributionDetails.donationAmount | currency }}</b
            ></label
          >
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Status: &nbsp;</label>
          <b-alert
            v-if="contributionDetails.status == 'SUCCESS'"
            variant="success"
            class="mb-2"
            show
          >
            {{ contributionDetails.status }}
          </b-alert>
          <b-alert v-else variant="danger" class="mb-2" show>
            &nbsp;{{ contributionDetails.status }}</b-alert
          >
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Date:</label>
          <label class="p-2"
            ><b>&nbsp;{{ juspayStatusDetails.createdAt }}</b></label
          >
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Receipt Id:</label>
          <label v-if="juspayStatusDetails.ReceiptId != null" class="p-2"
            ><b>&nbsp;{{ juspayStatusDetails.ReceiptId }}</b></label
          >
          <label v-else class="p-2"><b>&nbsp;NA</b></label>
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Transaction Id:</label>
          <label v-if="contributionDetails.transactionId != null" class="p-2"
            ><b>&nbsp;{{ juspayStatusDetails.transactionId }}</b></label
          >
          <label v-else class="p-2"><b>&nbsp;NA</b></label>
        </b-list-group-item>
      </v-card>
    </b-list-group>

    <b-list-group>
      <v-card>
        <v-card-title class="subheading font-weight-bold justify-content-center"
          >Personal Details
        </v-card-title>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Gender:</label>
          <label v-if="paymentDetails.gender != null" class="p-2"
            ><b>&nbsp;{{ paymentDetails.gender }}</b></label
          >
          <label v-else class="p-2"><b>&nbsp;NA</b></label>
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Date of Birth:</label>
          <label v-if="paymentDetails.dob != null" class="p-2"
            ><b>&nbsp;{{ paymentDetails.dob }}</b></label
          >
          <label v-else class="p-2"><b>&nbsp;NA</b></label>
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Address:</label>
          <label class="p-2"
            ><b>&nbsp;{{ paymentDetails.address }}</b></label
          >
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>City:</label>
          <label class="p-2"
            ><b>&nbsp;{{ paymentDetails.city }}</b></label
          >
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>District:</label>
          <label v-if="paymentDetails.district != null" class="p-2"
            ><b>&nbsp;{{ paymentDetails.district }}</b></label
          >
          <label v-else class="p-2"><b>&nbsp;NA</b></label>
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Postal Code:</label>
          <label v-if="paymentDetails.postal_code != null" class="p-2"
            ><b>&nbsp;{{ paymentDetails.postal_code }}</b></label
          >
          <label v-else class="p-2"><b>&nbsp;NA</b></label>
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>State:</label>
          <label class="p-2"
            ><b>&nbsp;{{ paymentDetails.state }}</b></label
          >
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Country:</label>
          <label class="p-2"
            ><b>&nbsp;{{ paymentDetails.country }}</b></label
          >
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>PAN:</label>
          <label class="p-2"
            ><b>&nbsp;{{ paymentDetails.pan_no }}</b></label
          >
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Mobile No:</label>
          <label class="p-2"
            ><b>&nbsp;{{ paymentDetails.phone }}</b></label
          >
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Email:</label>
          <label v-if="paymentDetails.email != null" class="p-2"
            ><b>&nbsp;{{ paymentDetails.email }}</b></label
          >
          <label v-else class="p-2"><b>&nbsp;NA</b></label>
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-center align-items-center align-self-stretch"
        >
          <label>Referred By:</label>
          <label v-if="paymentDetails.referred_by != null" class="p-2"
            ><b>&nbsp;{{ paymentDetails.referred_by }}</b></label
          >
          <label v-else class="p-2"><b>&nbsp;NA</b></label>
        </b-list-group-item>
      </v-card>
    </b-list-group>
  </v-container>
</template>

<style scoped>
.response__container {
  max-width: 55%;
}
@media screen and (max-width: 600px) {
  .response__container {
    max-width: 100%;
  }
}
</style>

<script>
import ApiService from "@/core/services/api.service";
import Vue from "vue";
Vue.filter("currency", function(value) {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0
  });
  return formatter.format(value);
});
export default {
  name: "contribute",
  data() {
    return {
      panelItems: [],
      donationId: this.$route.query.razorpay_invoice_id,
      paymentData: "",
      paymentDetails: "",
      contributionDetails: {},
      juspayStatus: "",
      juspayStatusDetails: "",
      receiptDetails: "",
      receiptId: "",
      receiptPresent: "",
      juspayStatusURL: "",

      paymentStatusDetail: {
        status: "",
        status_id: ""
      },

      receiptData: {
        transactionId: "",
        donationId: "",
        projectId: "",
        donorId: "",
        paymentType: "",
        paymentMethodType: ""
      }
    };
  },
  created: function() {
    this.getPageContents(), this.paymentStatus();
  },
  mounted: function() {},
  methods: {
    getPageContents() {
      ApiService.get("/organisation").then(({ data }) => {
        this.panelItems = data["panelItems"];
        localStorage.setItem("PanelItems", JSON.stringify(this.panelItems));
      });
    },
    paymentStatus() {
      let orderId = `${this.donationId}`;

      //Receipt button Displayed only if status == "CHARGED"
      this.juspayStatusURL = this.$route.query.status;
      //Get Status from Juspay
      ApiService.get("/paymentStatus", orderId).then(({ data }) => {
        this.juspayStatus = data;
        this.juspayStatusDetails = this.juspayStatus;
        this.contributionDetails.donationId = this.juspayStatusDetails.donationId;
        this.contributionDetails.donationAmount = this.juspayStatusDetails.amount;
        this.contributionDetails.transactionId = this.juspayStatusDetails.txn_id;
        if (this.juspayStatusDetails.status == "issued") {
          this.contributionDetails.status = "SUCCESS";
        } else {
          this.contributionDetails.status = this.juspayStatusDetails.status;
        }
        //Check Receipt already there in DB
        ApiService.get("/checkReceipt", this.juspayStatusDetails.order_id).then(
          ({ data }) => {
            this.receiptPresent = data;
            if (this.receiptPresent.status == "TRUE") {
              this.receiptId = this.receiptPresent.Receipt[0].receipt_id;
            } else {
              this.receiptId = null;
            }
            this.contributionDetails.receiptId = this.receiptId;
            //If Status == False (Receipt data is present, on Page Refresh not updating DB)
            if (this.receiptPresent.status == "FALSE") {
              if (this.juspayStatusDetails.status == "CHARGED") {
                //If Success data for Updating DB
                this.paymentStatusDetail.status = "SUCCESS";
                //Receipt Generation (Data store in DB)
                this.receiptData.transactionId = this.juspayStatusDetails.txn_id;
                this.receiptData.donationId = this.juspayStatusDetails.order_id;
                this.receiptData.projectId = this.juspayStatusDetails.product_id;
                this.receiptData.donorId = this.juspayStatusDetails.customer_id;
                this.receiptData.paymentType = "Online";
                this.receiptData.paymentMethodType = this.juspayStatusDetails.payment_method_type;
                ApiService.post("/receiptGenerate", this.receiptData).then(
                  ({ data }) => {
                    this.receiptDetails = data;
                    //To generate Receipt
                    this.receiptId = this.receiptDetails.donation["receiptId"];
                    this.contributionDetails.receiptId = this.receiptId;
                    //Email Receipt to Donor
                    ApiService.get("/userReceiptEmail", this.receiptId);
                  }
                );
              } else {
                this.paymentStatusDetail.status = this.juspayStatusDetails.status;
              }
              this.paymentStatusDetail.status_id = this.juspayStatusDetails.status_id;
              //Updating status in Donation DB
              let statusDetails = this.paymentStatusDetail;
              this.contributionDetails.status = this.paymentStatusDetail.status;
              ApiService.update("/paymentStatus", orderId, statusDetails);
            }
            //Details to display in Page
            ApiService.get("donarDonationview/" + orderId).then(({ data }) => {
              this.paymentData = data;
              this.paymentDetails = this.paymentData.donationDetails[0];
              var donorName = this.paymentDetails.donor_name;
              var projName = this.paymentDetails.project_name;
              var createdAt = this.paymentDetails.created_at;
              this.contributionDetails.donorName = donorName;
              this.contributionDetails.projectName = projName;
              this.contributionDetails.createdAt = createdAt;
            });
          }
        );
      });
    },
    //Downloading receipt
    receipt() {
      ApiService.getPDF("userGetReceipt", this.receiptId).then(response => {
        var file = new Blob([response.data], { type: "application/pdf" });
        var filename = this.receiptId + ".pdf";
        const pdfData = window.URL.createObjectURL(file);
        var link = document.createElement("a");
        link.href = pdfData;
        link.download = filename;
        link.click();
      });
    },

    //Redirect to home
    home() {
      this.$router.push({ name: "contribute" });
    }
  }
};
</script>
